import "core-js/modules/es.array.push.js";
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css'

export default {
  name: "RoleBindArea",
  props: ['role'],
  emits: ['on-close', 'on-submit'],
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      areas: [],
      areaIds: [],
      areaProps: {
        label: data => {
          return `${data.name}（${data.code}）`;
        }
      }
    };
  },
  methods: {
    submit() {
      let areaIds = this.$refs.areaTreeRef.getCheckedKeys();
      this.$emit('on-submit', this.role.roleId, areaIds);
    },
    cancel() {
      this.$emit('on-close');
    },
    areaTreeToArray(areaTree, areas) {
      areaTree.forEach(item => {
        areas.push(item);
        if (item.children) {
          this.areaTreeToArray(item.children, areas);
        }
      });
    }
  },
  beforeMount() {
    const loading = ElLoading.service();
    Promise.all([this.$api.uac.area.listByRole(this.role.roleId), this.$api.uac.area.list()]).then(res => {
      if (res[0].code === 200 && res[1].code === 200) {
        this.areas = res[1].data;
        if (res[0].data) {
          const t = [];
          this.areaTreeToArray(this.areas, t);
          res[0].data.forEach(permission => {
            const p = t.filter(v => v.id === permission.id);
            // 只把叶子节点作为已选择的权限
            if (p.length > 0 && !p[0].children) {
              this.areaIds.push(permission.id);
            }
          });
        }
      }
    }).finally(() => {
      loading.close();
    });
  }
};