import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-743056ee"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "info"
};
const _hoisted_2 = {
  class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_tree = _resolveComponent("el-tree");
  const _component_el_button = _resolveComponent("el-button");
  const _directive_permission = _resolveDirective("permission");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
    gutter: 10
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 8
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, "角色名称：" + _toDisplayString($props.role.roleName), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 16
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, "备注：" + _toDisplayString($props.role.remark), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_divider, {
    "content-position": "left"
  }, {
    default: _withCtx(() => [_createTextVNode("区域信息")]),
    _: 1
  }), _createVNode(_component_el_tree, {
    class: "permission-tree",
    data: $data.areas,
    "show-checkbox": "",
    "node-key": "id",
    "default-checked-keys": $data.areaIds,
    props: $data.areaProps,
    ref: "areaTreeRef"
  }, null, 8, ["data", "default-checked-keys", "props"]), _createVNode(_component_el_row, {
    justify: "center",
    style: {
      "margin-top": "10px"
    }
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
      type: "primary",
      onClick: $options.submit
    }, {
      default: _withCtx(() => [_createTextVNode("保存")]),
      _: 1
    }, 8, ["onClick"])), [[_directive_permission, 'role_bind_area']]), _createVNode(_component_el_button, {
      onClick: $options.cancel
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  })]);
}