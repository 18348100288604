import "core-js/modules/es.array.push.js";
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import BindUser from './bindUser';
import bindPermission from './bindPermission';
import bindArea from './bindArea';
import { mixins } from "@/plugins/mixins";
export default {
  name: "Role",
  components: {
    BindUser,
    bindPermission,
    bindArea
  },
  mixins: [mixins],
  data() {
    return {
      currentRole: null,
      bindUserDialog: false,
      bindPermissionDialog: false,
      bindAreaDialog: false,
      bindParkingIotDialog: false,
      columns: [{
        label: '角色名称',
        prop: 'roleName'
      }, {
        label: '备注',
        prop: 'remark'
      }],
      topButtons: [{
        name: '新增',
        code: 'add',
        type: 'primary',
        click: this.roleAdd,
        permissions: ['role_add']
      }],
      linkButtons: [{
        name: '删除',
        code: 'role_del',
        click: this.roleDel,
        permissions: ['role_del']
      }, {
        name: '修改',
        code: 'role_edit',
        click: this.roleEdit,
        permissions: ['role_edit']
      }, {
        name: '绑定用户',
        code: 'role_bind_user',
        click: this.roleBindUser,
        permissions: ['role_bind_user']
      }, {
        name: '分配权限',
        code: 'role_bind_permission',
        click: this.roleBindPermission,
        permissions: ['role_bind_permission']
      }, {
        name: '绑定区域',
        code: 'role_bind_area',
        click: this.roleBindArea,
        permissions: ['role_bind_area']
      }, {
        name: '绑定停车场',
        code: 'role_bind_parking_iot',
        click: this.roleBindParkingIot,
        permissions: ['role_bind_parking_iot']
      }]
    };
  },
  methods: {
    roleAdd() {
      this.$router.push('/roleAdd');
    },
    roleEdit(row) {
      this.$router.push({
        path: '/roleAdd',
        query: {
          roleId: row.roleId
        }
      });
    },
    roleDel(row) {
      ElMessageBox.confirm('确认要删除该角色吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.uac.role.del(row.roleId).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    roleBindUser(row) {
      this.currentRole = row;
      this.bindUserDialog = true;
    },
    doBindUser(roleId, userIds) {
      const loading = ElLoading.service();
      this.$api.uac.role.bindUser(roleId, userIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindUserDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    roleBindPermission(row) {
      this.currentRole = row;
      this.bindPermissionDialog = true;
    },
    doBindPermission(roleId, permissionIds) {
      const loading = ElLoading.service();
      this.$api.uac.role.bindPermission(roleId, permissionIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindPermissionDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    roleBindArea(row) {
      this.currentRole = row;
      this.bindAreaDialog = true;
    },
    doBindArea(roleId, areaIds) {
      const loading = ElLoading.service();
      this.$api.uac.role.bindArea(roleId, areaIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindAreaDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    },
    roleBindParkingIot(row) {
      this.currentRole = row;
      this.bindParkingIotDialog = true;
    },
    doBindParkingIot(roleId, parkingIotIds) {
      const loading = ElLoading.service();
      this.$api.uac.role.bindParkingIot(roleId, parkingIotIds).then(res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.bindParkingIotDialog = false;
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};