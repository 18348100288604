import "core-js/modules/es.array.push.js";
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css'

export default {
  name: "RoleBindPermission",
  props: ['role'],
  emits: ['on-close', 'on-submit'],
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      permissions: [],
      permissionIds: [],
      permissionProps: {
        label: data => {
          return `${data.permissionName}（${data.permissionCode}）`;
        }
      }
    };
  },
  methods: {
    submit() {
      let permissionIds = this.$refs.permissionTreeRef.getCheckedKeys().concat(this.$refs.permissionTreeRef.getHalfCheckedKeys());
      this.$emit('on-submit', this.role.roleId, permissionIds);
    },
    cancel() {
      this.$emit('on-close');
    },
    permissionTreeToArray(permissionTree, permissions) {
      permissionTree.forEach(item => {
        permissions.push(item);
        if (item.children) {
          this.permissionTreeToArray(item.children, permissions);
        }
      });
    }
  },
  beforeMount() {
    const loading = ElLoading.service();
    Promise.all([this.$api.uac.permission.listByRole(this.role.roleId), this.$api.uac.permission.menuByOrg(this.role.orgId)]).then(res => {
      if (res[0].code === 200 && res[1].code === 200) {
        this.permissions = res[1].data;
        if (res[0].data) {
          const t = [];
          this.permissionTreeToArray(this.permissions, t);
          res[0].data.forEach(permission => {
            const p = t.filter(v => v.permissionId === permission.permissionId);
            // 只把叶子节点作为已选择的权限
            if (p.length > 0 && !p[0].children) {
              this.permissionIds.push(permission.permissionId);
            }
          });
        }
      }
    }).finally(() => {
      loading.close();
    });
  }
};