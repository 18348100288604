import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b6cd895a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "info"
};
const _hoisted_2 = {
  class: "info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
  const _component_el_button = _resolveComponent("el-button");
  const _directive_permission = _resolveDirective("permission");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_divider, {
    "content-position": "left"
  }, {
    default: _withCtx(() => [_createTextVNode("角色信息")]),
    _: 1
  }), _createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 8
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_1, "角色名称：" + _toDisplayString($props.role.roleName), 1)]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 16
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, "备注：" + _toDisplayString($props.role.remark), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_divider, {
    "content-position": "left"
  }, {
    default: _withCtx(() => [_createTextVNode("用户信息")]),
    _: 1
  }), _createVNode(_component_el_checkbox, {
    modelValue: $data.checkAll,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.checkAll = $event),
    indeterminate: $data.isIndeterminate,
    onChange: $options.handleCheckAllChange
  }, {
    default: _withCtx(() => [_createTextVNode("全选 ")]),
    _: 1
  }, 8, ["modelValue", "indeterminate", "onChange"]), _createVNode(_component_el_checkbox_group, {
    modelValue: $data.selectedUserIds,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.selectedUserIds = $event),
    onChange: $options.handleCheckedColumnIndexChange
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.userList, user => {
      return _openBlock(), _createBlock(_component_el_checkbox, {
        key: user.userId,
        label: user.userId
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(user.userName) + "(" + _toDisplayString(user.userCode) + ")", 1)]),
        _: 2
      }, 1032, ["label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"]), _createVNode(_component_el_row, {
    justify: "center",
    style: {
      "margin-top": "10px"
    }
  }, {
    default: _withCtx(() => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
      type: "primary",
      onClick: $options.submit
    }, {
      default: _withCtx(() => [_createTextVNode("保存")]),
      _: 1
    }, 8, ["onClick"])), [[_directive_permission, 'role_bind_user']]), _createVNode(_component_el_button, {
      onClick: $options.cancel
    }, {
      default: _withCtx(() => [_createTextVNode("取消")]),
      _: 1
    }, 8, ["onClick"])]),
    _: 1
  })]);
}