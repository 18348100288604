import "core-js/modules/es.array.push.js";
import { ElLoading } from 'element-plus';
import 'element-plus/es/components/loading/style/css'

export default {
  name: "RoleBindUser",
  props: ['role'],
  emits: ['on-close', 'on-submit'],
  data() {
    return {
      checkAll: false,
      isIndeterminate: false,
      userList: [],
      selectedUserIds: []
    };
  },
  methods: {
    handleCheckAllChange(checkAll) {
      this.selectedUserIds = [];
      if (checkAll) {
        this.userList.forEach(user => {
          this.selectedUserIds.push(user.userId);
        });
        this.isIndeterminate = false;
      }
    },
    handleCheckedColumnIndexChange(value) {
      this.isIndeterminate = value.length > 0 && value.length < this.userList.length;
      this.checkAll = value.length === this.userList.length;
    },
    submit() {
      this.$emit('on-submit', this.role.roleId, this.selectedUserIds);
    },
    cancel() {
      this.$emit('on-close');
    }
  },
  beforeMount() {
    const loading = ElLoading.service();
    Promise.all([this.$api.uac.user.queryByRole(this.role.roleId), this.$api.uac.user.list({})]).then(res => {
      if (res[0].code === 200 && res[1].code === 200) {
        this.userList = res[1].data;
        if (res[0].data) {
          res[0].data.forEach(user => this.selectedUserIds.push(user.userId));
        }
        this.isIndeterminate = this.selectedUserIds.length > 0 && this.selectedUserIds.length < this.userList.length;
        this.checkAll = this.selectedUserIds.length === this.userList.length;
      }
    }).finally(() => {
      loading.close();
    });
  }
};